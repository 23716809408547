@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Jost:wght@500&display=swap");

/* Estilo del scroll  */
  
@layer body {
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  
  .content {
    position: relative;
    z-index: 1;
  }
  
}


  /* Lista */

  @layer base {
  
    li {
      font-size: 150%;
      @apply px-4 font-[Jost];
      @apply cursor-pointer drop-shadow-xl
    }
  
    li:hover {
    
      color: #ff0000;
      transition-duration: 400ms;
  
    }
  
    #list li {
      @apply -translate-y-6 translate-x-6;
      font-size: 140%;
      padding-top: 2%;
    }
  
    #list img {
  
     @apply translate-x-3 translate-y-0 scale-50;
    }
    
  }


/* Lista Mobile */

  .bg-imgs {
    background-color: rgba(0, 0, 0, 0.668);
    }

  .bg-imgs2 {
    background-color: rgb(34, 34, 34);
    }
    
  h1 {
      @apply pt-10 font-[Jost];
      font-size: 3rem;
    }

  .fade-enter {
      opacity: 0;
    }

  .fade-enter-active {
      opacity: 1;
      transition: opacity 500ms;
    }

  .fade-exit {
      opacity: 1;
    }

  .fade-exit-active {
      opacity: 0;
      transition: opacity 500ms;
    }


  /* Reproductor */

  #btnP2 {
      @apply absolute -top-5 w-full h-full flex flex-col justify-end items-center;
    }

  #audio-player {
      display: none;
    }

  .fa-play,
  .fa-pause {
      font-size: 3rem;
      color: rgb(255, 255, 255);
      margin: 10px;
    }

  .fa-play:hover {
      @apply hover:scale-125 duration-500;
      color: #ff0000;
    }
    
  .fa-pause:hover {
      @apply hover:scale-125 duration-500;
      color: #ff0000;
    }


@layer components {
/* Backgorund de todo */

    .bg-img {
        background-image: url("../src/assets/bg/dirt.jpg");
      }

    #carou .carousel-item {
          height: 35rem;
      }
}

/* Mobiles maximo 786 */

@media (max-width: 768px){

  #carou {
    height: 23rem;
    @apply p-3
  }
  #prog {
    @apply scale-100
  }

}

/* Mobiles minimo 768 */

@media not all and (min-width: 768px) { 
  h1 {
    font-size: 2rem;
  }

  #carou {
    height: 13rem;
    @apply p-3
  }

  .bg-imgs {
    background-color: rgb(34, 34, 34);
    }

    #contact {
      @apply px-4
    }
  }
  
  .popu {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }






